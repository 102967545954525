import { StringTests, ResponseType, AlertType } from '../../../constants';

export default {
  name: 'ResetPasswordForm',

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    store: {
      type: String,
      default: 'login-extended'
    },
  },
  data() {
    return {
      formSending: false,
      formSent: false,
      message: null,
      valid: true,
      password: null,
      passwordRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.password_required'),
        (v) => (typeof v === 'string' && v.length >= 6) || this.$vuetify.lang.t('$vuetify.alerts.password_minlength'),
      ],
      confirmPassword: null,
      confirmPasswordRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.confirmPassword_required'),
        (v) => (typeof v === 'string' && v.length >= 6) || this.$vuetify.lang.t('$vuetify.alerts.password_minlength'),
        (v) =>
          this.password === this.confirmPassword || this.$vuetify.lang.t('$vuetify.alerts.confirmPassword_notMatch'),
      ],
    };
  },
  methods: {
    validateForm() {
      return this.$refs.resetPasswordForm.validate();
    },
    resetForm() {
      this.$refs.resetPasswordForm.reset();
    },
    async submit() {
      if (this.validateForm()) {
        this.formSending = true;
        const passwordRecoveryResult = await this.passwordRecovery();

        if (passwordRecoveryResult.type === ResponseType.SUCCESS) {
          this.resetForm();
          this.message = {
            type: AlertType.success,
            text: this.$vuetify.lang.t('$vuetify.resetPasswordForm.formSentText'),
          };
        } else {
          this.message = {
            type: AlertType.error,
            text: this.$vuetify.lang.t('$vuetify.resetPasswordForm.formSendingErrorText'),
          };
        }

        this.formSending = false;
        this.formSent = true;
        return true;
      }

      return false;
    },
    async passwordRecovery() {
      const { email, password, token } = this;

      const passwordRecoveryResult = await this.$store.dispatch(`${this.store}/passwordRecovery`, {
        email,
        password,
        token,
      });

      return passwordRecoveryResult;
    },
  },
};
